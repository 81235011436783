
import { reactive } from "vue";
import { Inject, mixins, Options, Watch } from "vue-property-decorator";
import { ArrowRight, GetUserMixin, GigyaError, Storage } from "@rtl/ui";
import { VoteAnswer } from "@/api";
import { vVisible } from "@/directives";
import { VoteItemFetcher } from "@/mixins";
import { VOTE_NAMESPACE, VOTE_ACTION, VoteCreatePayload } from "@/store";
import { Fetcher } from "@/utils";
import { Platform } from "@rtl/ui";

import {
  Container,
  CtaButton,
  Error,
  Page,
  TextContent,
  TransitionStyle,
  VoteForm,
} from "@/views/components";

@Options({
  components: {
    ArrowRight,
    Container,
    CtaButton,
    Error,
    Page,
    TextContent,
    TransitionStyle,
    VoteForm,
  },
  directives: {
    visible: vVisible,
  },
})
export default class VoteFormPage extends mixins(
  GetUserMixin,
  VoteItemFetcher
) {
  @Inject()
  platform!: Platform;

  formData: Array<VoteAnswer> = [];
  persistedData: Array<VoteAnswer> = [];
  selectedSubjects: Record<string, unknown | null> = {};
  questionIndex = 0;
  unlocked = false;

  @Watch("vote", { immediate: true, deep: true })
  checkStatus() {
    if (this.vote) {
      if (!this.user) {
        this.$router.replace({
          name: "vote-index",
          params: this.vote.$linkParams,
        });
      } else if (!this.vote.isRunning) {
        this.$router.replace({
          name: "vote-pending",
          params: this.vote.$linkParams,
        });
      } else {
        const data = this.storedVoteData;
        if (data && !this.unlocked) {
          this.persistedData = data;
        }
      }
    }
  }

  get readonly() {
    const error = String(this.voteSubmitter.error);
    if (error.indexOf("E11000") >= 0) {
      return true;
    }
    if (this.unlocked) {
      return false;
    }
    return (
      (!this.vote?.form.batchable || this.formIsFullfilled) &&
      !!this.storedVoteData
    );
  }

  get voteSubmitter() {
    const fetcher = new Fetcher(
      async (payload: Omit<VoteCreatePayload, "token">) => {
        const JWT = await this.jwt();
        if (JWT) {
          return this.$store.dispatch(
            `${VOTE_NAMESPACE}/${VOTE_ACTION.CREATE_VOTE}`,
            {
              ...payload,
              token: JWT.id_token,
            }
          );
        }
      }
    );

    return reactive(fetcher);
  }

  get hasFormData() {
    return this.formData.some((answer) => answer.value !== null);
  }

  get hasAuthError() {
    return this.voteSubmitter.error instanceof GigyaError;
  }

  hasPersistedData(qIndex: number) {
    const question = this.vote?.form.questions[qIndex];
    return (
      question &&
      this.persistedData.some((answer) => {
        return answer.question_id === question.id && answer.value !== null;
      })
    );
  }

  get storedVoteData() {
    if (this.vote && this.user) {
      const storageKey = this.vote.$getStorageKey(this.user.UID);
      const datas = Storage.get(storageKey) as Array<VoteAnswer> | undefined;
      return datas?.map((data) => {
        const question = this.vote?.form.questions.find((question) => {
          return question.id === data.question_id;
        });
        const subject = question?.subjects.find((subject) => {
          return subject.alias === data.subject_code;
        });
        if (subject) {
          data.subject_code = subject.code;
        }
        return data;
      });
    }
    return null;
  }

  get formIsFullfilled() {
    return !this.vote?.form.questions.some((question, i) => {
      const count = this.countAnswers(i, this.persistedData);
      const isFull = !!(question.type === "battle"
        ? count >= (question.max || 1)
        : count >= (question.max || question.subjects.length));

      return !isFull;
    });
  }

  closeSelection() {
    this.selectedSubjects = {};
  }

  hasSelection(qIndex: number) {
    const question = this.vote?.form.questions[qIndex];
    return (
      !!question &&
      !!Object.keys(this.selectedSubjects[question.id] || {}).length
    );
  }

  hasSelectionData(qIndex: number) {
    return this.countAnswers(qIndex, this.formData);
  }

  countAnswers(qIndex: number, answers: Array<VoteAnswer>) {
    const question = this.vote?.form.questions[qIndex];
    if (!question) {
      return 0;
    }
    return answers.filter((answer) => {
      return answer.question_id === question.id && answer.value !== null;
    }).length;
  }

  formIsValid(qIndex: number) {
    const question = this.vote?.form.questions[qIndex];
    const formDataLength = this.countAnswers(qIndex, this.formData);
    const persistedLength = this.countAnswers(qIndex, this.persistedData);
    const answersLength = formDataLength + persistedLength;

    return (
      answersLength &&
      (!question?.min || question.min <= answersLength) &&
      (!question?.max || question.max >= answersLength)
    );
  }

  async formSubmit() {
    if (this.vote && this.user) {
      let advmeta = "";
      try {
        const meta = document.querySelector('meta[name="advmeta-cc"]');
        advmeta = meta?.getAttribute("content") || "";
      } catch {
        // eslint-disable-next-line: no-console
        console.log("no meta");
      }
      try {
        await this.voteSubmitter.run({
          url: this.vote.form.action,
          data: {
            user: this.user.UID,
            answers: this.formData,
            platform: this.platform,
            modify: this.unlocked,
            advmeta,
          },
        });
      } catch (error) {
        if (String(error).startsWith("Error: 410")) {
          this.$router.replace({ name: "vote-list" });
          return;
        } else {
          throw error;
        }
      }

      const answers = [...this.persistedData, ...this.formData];
      const persistedData = answers.filter((answer) => answer.value !== null);
      // this.unlocked = false;
      this.formData = [];
      this.persistedData = persistedData;
      Storage.set(this.vote.$getStorageKey(this.user.UID), persistedData);

      if (!this.vote.form.batchable || this.formIsFullfilled) {
        this.goToEnd();
      } else {
        this.closeSelection();
      }
    }
  }

  goToEnd() {
    this.$router.replace({
      name: "vote-thanks",
      params: this.vote?.$linkParams,
    });
  }

  unlock() {
    this.voteSubmitter.error = null;
    this.unlocked = true;
    this.formData = [];
    this.persistedData = [];
  }
}
