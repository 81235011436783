import { reactive } from "vue";
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import { Vote } from "@/models";
import { VoteItemPayload, VOTE_NAMESPACE, VOTE_ACTION } from "@/store";
import { Fetcher } from "@/utils";

@Options({})
export default class VoteItemFetcher extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly group!: string;

  @Prop({
    type: String,
    required: true,
  })
  readonly slug!: string;

  voteItemRefreshed = false;

  get vote(): Vote | undefined {
    return this.voteItemFetcher.item;
  }

  get voteItemFetcher() {
    const fetcher = new Fetcher((payload: VoteItemPayload) => {
      return this.$store.dispatch(
        `${VOTE_NAMESPACE}/${VOTE_ACTION.FETCH_ITEM}`,
        payload
      );
    }, Vote);

    return reactive(fetcher);
  }

  get voteItemPayload() {
    return {
      group: this.group,
      slug: this.slug,
    };
  }

  @Watch("voteItemPayload", { deep: true })
  async handleVoteItem() {
    return this.fetchVoteItem();
  }

  async fetchVoteItem(cached = true) {
    try {
      return await this.voteItemFetcher.run({
        data: this.voteItemPayload,
        cached,
      });
    } catch (e) {
      // eslint-disable-next-line: no-console
      console.error(e);
      this.$router.replace({ name: "vote-list" });
    }
  }

  async refreshVoteItem() {
    await this.fetchVoteItem(false);

    this.voteItemRefreshed = true;
    setTimeout(() => {
      this.voteItemRefreshed = false;
    }, 1000);
  }

  async visibilityVoteItemHandler() {
    if (!document?.hidden) {
      await this.fetchVoteItem(false);
    }
  }

  serverPrefetch() {
    return this.fetchVoteItem();
  }

  beforeMount() {
    return this.fetchVoteItem();
  }

  mounted() {
    document?.addEventListener("visibilitychange", () => {
      this.visibilityVoteItemHandler();
    });
  }

  beforeUnmount() {
    document?.removeEventListener("visibilitychange", () => {
      this.visibilityVoteItemHandler();
    });
  }
}
