
import { Options, Provide, mixins, Watch } from "vue-property-decorator";
import { GetUserMixin, Modal, platform, Platform, Storage } from "@rtl/ui";
import { AppOAuthMixin } from "@/mixins";

@Options({
  head() {
    return {
      title: "RTL.hu Szavazás",
      description:
        "Itt tudsz részt venni az RTL élő műsoraihoz kapcsolódó szavazásokon.",
    };
  },
  components: {
    Modal,
  },
})
export default class App extends mixins(AppOAuthMixin, GetUserMixin) {
  @Provide()
  get platform() {
    return platform();
  }

  @Provide()
  get rtlUrl() {
    const appHostname = "app.rtl.hu";

    switch (this.platform) {
      case Platform.IOS:
        return `capacitor://${appHostname}`;
      case Platform.ANDROID:
        return `https://${appHostname}`;
      default:
        return "https://rtl.hu";
    }
  }

  @Provide()
  get contentUrlPrefix() {
    return this.rtlUrl;
  }

  showPrivacyModal = false;

  closePrivacyModal() {
    this.showPrivacyModal = false;
  }

  @Watch("user", { immediate: true })
  chechPrivacyModal(newVal: unknown, oldVal?: unknown) {
    if (newVal && !oldVal && new Date().getFullYear() === 2024) {
      const expire = Storage.get("privacy-2024-modal-close");
      if (!expire || Date.now() > expire + 365 * 24 * 60 * 60 * 1000) {
        this.showPrivacyModal = true;
        Storage.set("privacy-2024-modal-close", Date.now());
      }
    }
  }
}
