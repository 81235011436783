
import { mixins, Options, Prop, Watch } from 'vue-property-decorator'
import { RouteLocationRaw } from 'vue-router'
import { Action } from 'vuex-class'

import { AccountUpdateRequest, UserResponse } from '../../gigya'
import { ROUTES } from '../../router'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import Checkbox from '../components/auth/Checkbox.vue'
import DateInput from '../components/auth/DateInput.vue'
import Select from '../components/auth/Select.vue'
import Modal from '../components/Modal.vue'
import AuthDataEditMixin from '../mixins/AuthDataEditMixin'

@Options({
  name: 'SocialRegFinish',
  components: {
    Modal,
    DateInput,
    Select,
    Checkbox
  }
})

export default class SocialRegFinish extends mixins(AuthDataEditMixin) {
  @Prop({ type: String, default: '' })
  readonly UID!: string

  @Prop({
    type: [String, Object],
    default: () => ({ name: ROUTES.INDEX })
  })
  readonly successUrl!: RouteLocationRaw

  @Action(USER_ACTION.UPDATE_ACCOUNT, { namespace: USER_NAMESPACE })
  updateAccountAction!: (params: AccountUpdateRequest) => Promise<UserResponse>

  @Action(USER_ACTION.FORCED_LOGOUT, { namespace: USER_NAMESPACE })
  forcedLogoutAction!: () => void

  registerData: AccountUpdateRequest = {
    UID: this.UID,
    profile: {
      gender: '',
      birthDay: 0,
      birthMonth: 0,
      birthYear: 0,
      birthPlace: '',
      zip: ''
    },
    data: {
      RTLHU_Consent: false,
      RTLHU_Newsletter: false
    }
  }

  genderOptions = [
    { value: '', label: 'Válassz!', disabled: true },
    { value: 'm', label: 'Férfi' },
    { value: 'f', label: 'Nő' },
    { value: 'u', label: 'Inkább nem válaszolok' }
  ]

  birthDate = ''
  modalIsOpen = true

  mounted () {
    this.registerData.UID = this.UID
  }

  @Watch('birthDate')
  updateBirthData (value: string): void {
    const date = new Date(value)
    if (this.registerData?.profile) {
      this.registerData.profile = {
        ...this.registerData.profile,
        birthDay: date.getDate(),
        birthMonth: date.getMonth() + 1,
        birthYear: date.getFullYear()
      }
    }
  }

  async register () {
    if (this.validateForm()) {
      await this.updateAccountAction(this.registerData)
      this.modalIsOpen = false
      this.$router.push(this.successUrl)
    }
  }

  async onModalClose () {
    await this.forcedLogoutAction()
    this.modalIsOpen = false
  }

  validateForm (): boolean {
    this.clearFormErrors()
    this.validateRequiredString(this.registerData.profile?.birthPlace || '', 'birthPlace', 'A születési helyed megadása kötelező')
    this.validateBirthDate()
    this.validateRequiredString(this.registerData.profile?.gender || '', 'gender', 'A nemed megadása kötelező')
    this.validateRequiredBoolean(this.registerData?.data?.RTLHU_Consent || false, 'RTLHU_Consent', 'Az adatkezelési tájékoztató és az általános szerződési feltételek elfogadása kötelező')

    return !this.hasFormError()
  }

  validateBirthDate () {
    const d = this.registerData.profile?.birthDay
    const m = this.registerData.profile?.birthMonth
    const y = this.registerData.profile?.birthYear

    if (!d || !m || !y) {
      this.addFormError({ field: 'birthDate', msg: 'A születési idő megadása kötelező' })
    }
  }
}
